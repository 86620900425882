<template>
	<DeliveryMap @mapReady="map=$event" v-bind:showMarkerPopup.sync="markerInfoShow" mapid="report" class="report-map" :hidePlacemark="true"/>
</template>
<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import qs from 'qs'
import axios from 'axios'
import DeliveryMap from '@/components/block/geo/DeliveryMap.vue'

export default {
	components: {
		DeliveryMap
	},
	data() {
		return {
			map: false,
			polygons: [],
			zones: false,
			zoneFilter: false,
			clusterer: [],
			points: false,
			customPoints: [],
			markerInfoShow: false,
			ready: false,
		}
	},
	computed: {
		...mapState('map', ['fullZones']),
	},
	methods: {
		...mapActions('map', ['getZones', 'drawZones', 'drawPlacemark','getDataOrders', 'getDataUsers']),
		...mapMutations('map', ['setDates']),

		drawMarks(points)
		{
			let Map = this.map
			points.forEach(el => {
				var pl = new ymaps.GeoObject(
					{
						// Описание геометрии.
						geometry:
							{
								type: "Point",
								coordinates: [el.lat, el.lon]
							},
						// Свойства.
						properties:
							{
								// Контент метки.
								iconContent: el.count,
							}
					},
					{
						preset: 'islands#redStretchyIcon'
					});
				pl.events.add('click', () => this.showPopup(el));
				Map.geoObjects.add(pl);
			})
		},
		showPopup(point)
		{
			this.markerInfoShow = true;
			this.$store.commit('map/setCoords', point);
			const start = this.$store.state.map.dates.start;
			const end = this.$store.state.map.dates.end;
			let fd = new FormData();
			fd.append('post[dateFrom]', `${('' + start.getDate()).padStart(2, 0)}.${('' + (start.getMonth()+1)).padStart(2, 0)}.${start.getFullYear()}`);
			fd.append('post[dateTo]', `${('' + end.getDate()).padStart(2, 0)}.${('' + (end.getMonth()+1)).padStart(2, 0)}.${end.getFullYear()}`);
			fd.append('post[unique]', '');
			fd.append('post[lat]', this.$store.state.map.coords.lat);
			fd.append('post[lon]', this.$store.state.map.coords.lon);
			this.getDataOrders(fd);
		},
		drawMark(coords)
		{
			let placemark = new ymaps.Placemark(coords, {}, {
				preset: 'islands#violetDotIconWithCaption',
				iconColor: 'violet',
				draggable: true,
			});
			this.map.geoObjects.add(placemark);
		},
		addClickEvent()
		{
			let self = this
			this.map.events.add('click', function (e)
			{
				var coords = e.get('coords');
				self.drawMark(coords);
				self.customPoints.push(coords[0])
				self.customPoints.push(coords[1])
				self.$route.query['coords[]'] = []
				self.$router.push({query: {...self.$route.query, 'coords[]': self.customPoints}});
			});
			this.polygons.forEach(el =>{
				el.events.add('click', function (e)
				{
					var coords = e.get('coords');
					self.drawMark(coords);
					self.customPoints.push(coords[0])
					self.customPoints.push(coords[1])
					self.$route.query['coords[]'] = []
					self.$router.push({query: {...self.$route.query, 'coords[]': self.customPoints}});
				});
			})
		},
		drawData()
		{
			if(!this.fullZones || !this.zoneFilter || !this.map || !this.points) return;
			const arr = this.zoneFilter
			this.zones = this.fullZones.filter(function(el){
				return arr.includes(el.UF_XML_ID)
			});
			for(let i = 0; i < this.customPoints.length; i+=2)
			{
				this.drawMark([this.customPoints[i], this.customPoints[i+1]])
			}
			this.drawZones(this);
			this.addClickEvent();
			this.drawMarks(this.points)
			this.ready = true
		},
		setupDates()
		{
			const start = this.$route.query.from.split('.');
			const end = this.$route.query.to.split('.');
			this.setDates({
				start: new Date(`${start[2]}-${start[1]}-${start[0]}`),
				end: new Date(`${end[2]}-${end[1]}-${end[0]}`)
			});
		}
	},
	async mounted()
	{
		await this.getDataOrders;
		this.zoneFilter = this.$route.query['zones[]']
		this.customPoints = 'coords[]' in this.$route.query ? this.$route.query['coords[]'] : []
		if(!this.fullZones)
		{
			await this.getZones();
		}
		let data = qs.stringify({
			post:{
				'ajax' : true,
				'zones' : this.zoneFilter,
				'dateFrom' : this.$route.query.from,
				'dateTo' : this.$route.query.to,
				'unique' : 'unique' in this.$route.query
			}
		});
		const instance = axios.create({
			baseURL: '/'
		});
		let resp = await instance.post('/bitrix/services/main/ajax.php?c=intervolga:dict2gis.form&action=getOrders&mode=class', data);
		this.points = resp.data.data.response;
		if(!this.ready) this.drawData();

		await this.setupDates();
	},
	watch:{
		async map(){
			if(!this.ready) this.drawData();
		}
	}
}
</script>
<style lang="scss">
.report-map {
	min-height: 800px;
	.map {
		min-height: 800px;
	}
}
</style>